import * as React from 'react';
import { Container } from 'reactstrap';
import BaseLayout from '../components/Layouts/BaseLayout';
import CarouselSlideshow from "../components/Carousel/Carousel";
import * as homeJson from "../data/home.json";

const IndexPage = ({ location, carousel }) => {
  return (
    <BaseLayout location={location} title="Home">
      <header className="page-header small bg-secondary">
        <Container>
          <div className="header-content text-center">
            <h1 className="header-title bg-transparent">JUSTIN SCROGGINS</h1>
          </div>
        </Container>
      </header>

      <div className="p-5">
        <CarouselSlideshow items={carousel || []} />
      </div>
    </BaseLayout>
  );
};

export default ({ location }) => <IndexPage location={location} {...homeJson} />;
